@import "../../../../themes/wcmc_bootstrap/scss/_variables";
@import "../../../../themes/wcmc_bootstrap/scss/_mixins";

/* =============================================================================
   ~Carousel Slides
   ========================================================================== */

/* =Base Theme Layout Adjustment
----------------------------------------------------------------------------------*/

/* We want the carousel to extend to 100% of the screen, so need to remove existing layout constraint.
* (Applies only to home page)
*/

.front #main {
  max-width: none;
  margin: 0;
  padding: 0;
}

/* =Setup Symbol Set Webfont (used on Mobile Slide Counter)
----------------------------------------------------------------------------------*/

@font-face {
  font-family: "SSStandard";
  src: url('/profiles/wcmc/themes/wcmc_base/webfonts/ss-standard.eot');
  src: url('/profiles/wcmc/themes/wcmc_base/webfonts/ss-standard.eot?#iefix') format('embedded-opentype'),
       url('../../../../themes/wcmc_base/webfonts/ss-standard.woff') format('woff'),
       url('../../../../themes/wcmc_base/webfonts/ss-standard.ttf')  format('truetype'),
       url('../../../../themes/wcmc_base/webfonts/ss-standard.svg#SSStandard') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('/profiles/wcmc/themes/wcmc_bootstrap/fonts/bootstrap/glyphicons-halflings-regular.eot');
  src: url('/profiles/wcmc/themes/wcmc_bootstrap/fonts/bootstrap/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
       url('../../../../themes/wcmc_bootstrap/fonts/bootstrap/glyphicons-halflings-regular.woff2') format('woff2'),
       url('../../../../themes/wcmc_bootstrap/fonts/bootstrap/glyphicons-halflings-regular.woff') format('woff'),
       url('../../../../themes/wcmc_bootstrap/fonts/bootstrap/glyphicons-halflings-regular.ttf') format('truetype'),
       url('../../../../themes/wcmc_bootstrap/fonts/bootstrap/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

/* =Basic styles (for use with Cycle2 plugin)
----------------------------------------------------------------------------------*/

.view-carousel {
  position: relative;

  .cycle-slideshow {
    @include breakpoint($md) {
      background: $wcm-black;
    }

    // hide all slides except the first one to avoid FOUC
    .views-row {
      display: none;
      position: relative;
      width: 100%;
    }

    .views-row-first {
      display: block;
    }

    /* =Previous / Next Arrow Overlays
    ----------------------------------------------------------------------------------*/
    // These only appear in Desktop View

    

    .cycle-pause, .cycle-prev, .cycle-next{
      font-family: 'Glyphicons Halflings';
      border: none;
      color: $wcm-white;
      z-index: 101;
      text-align: center;
      cursor: pointer;
      opacity: 0.7;

      &:hover{
        opacity: .9;
      }
    }

    .cycle-pause {
      position: absolute;
      display: block;
      top: 15px;
      left: 30px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      background-color: $wcm-med-gray;
      border-radius: 20px;
      padding: 0;
    }

    .cycle-prev, .cycle-next{
      font-size: 18px;
      background-color: $wcm-dark-orange;
    }

    .cycle-pause:before{
      content: "\e073";
    }

    .cycle-pause.paused:before{
      content: "\e072";
    }

    .cycle-prev, .cycle-next {
      display: none;
      position: absolute;
      top: 38%;
      transform: translateY(-50%);
      width: 30px;
      height: 50px;
      line-height: 50px;
      opacity: 0.7;

      @include breakpoint($sm) {
        display: block;
      }

      @include breakpoint($md) {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .cycle-prev {
      left: 0;

      &:before{
        content: "\e079";
      }
    }

    .cycle-next {
      right: 0;

      &:before{
        content: "\e080";
      }
    }

    .carousel-slide{

      /* =Slide Image
      ----------------------------------------------------------------------------------*/
      .slide-image{
        position: relative;
        width: 100%;
        height: 100%;

        /* =Optional Overlay Text
        ----------------------------------------------------------------------------------*/
        // Change the positioning slightly different between Tablet and Desktop
        .overlay-text {
          display: none;
          z-index: 1001;
          position: absolute;
          top: 45%;
          text-align: center;
          width: 100%;
          height: auto;
          margin: 0;
          opacity: .7;
          filter: alpha(opacity=70);
          @include breakpoint($md) {
            display: block;
          }
          @include breakpoint($sm) {
            top: 38%;
            font-size: 48px;
          }
        }//.overlay-text

        img {
          min-width: 320px;
          height: auto;
          @include breakpoint($md) {
            min-width: unset;
            width: 100%;
            position: relative;
            display: block;
          }
        }

        a{
          > img{
            border-radius: 0;
          }
        }

      }//.slide-image

      /* =Slide Text / Caption
      ----------------------------------------------------------------------------------*/
      .slide-text {
        padding: 15px;
        background: $wcm-dark-gray;

        @include breakpoint($md) {
          position: absolute;
          bottom: 0;
          width: 100%;
          opacity: .85;
          filter: alpha(opacity=85);
        }

        h2{
          font-size: 18px;
          line-height: 1;
          font-weight: normal;
          color: #fff;
          margin: 0;
          padding: 0 0 5px;
          @include breakpoint($md) {
            width: 30%;
            float: left;
            padding-top: 20px;
            padding-bottom: 10px;
            text-align: right;
          }

          a{
            color: #fff;
            &:hover {
              text-decoration: none;
            }
          }
        }

        p {
          font-size: 14px;
          color: $wcm-white;
          margin: 0;
          padding: 0;
          @include breakpoint($md) {
            line-height: 20px;
          }
          @include breakpoint($md) {
            width: 70%;
            float: left;
            padding: 10px 0 10px 40px;
            box-sizing: border-box;
          }

          a {
            color: #aeb8bf;
          }
        }
      }//.slide-text

    }//.carousel-slide

  }//.cycle-slideshow

  /* =Thumbail Pager
  ----------------------------------------------------------------------------------*/
  // The thumbnail pager is not displayed in mobile view
  .thumbnail-controller {
    display: none;
    @include breakpoint($sm) {
      display: block;
      background: $wcm-dark-gray;
      border-top: 1px solid #fff;
      padding: 6px 0;
      overflow: hidden;
    }

    .thumbnail-controller-wrap {
      .thumbnail-controller-wrap-inner {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-content: space-around;

        .pager {
          font-size: 12px;
          padding-top: 20px;
          text-transform: uppercase;
          flex: 0 1 75px;
        }

        .pager.previous {
          margin-right: 10px;
        }

        .pager.next {
          margin-left: 10px;
        }

        .thumbnail-pager {
          flex: 2 2;
          // Formula: data-cycle-carousel-visible * img width + ((data-cycle-carousel-visible - 1) * img padding)
          // 510px = 4 * 120px + 30px
          &.carousel_visible_4 {
            max-width: 4 * 120px + ((4 - 1) * 10px);
          }
          &.carousel_visible_3 {
            max-width: 3 * 120px + ((3 - 1) * 10px);
          }
          &.carousel_visible_2 {
            max-width: 2 * 120px + ((2 - 1) * 10px);
          }
          &.carousel_visible_1 {
            max-width: 1 * 120px + ((1 - 1) * 10px);
          }

          a {
            width: auto !important;
            margin: 0 10px 0 0 !important;

            &:last-of-type {
              padding: 0;
            }

            > img {
              max-width: none;
              width: 120px;
              height: 48px;
              border-radius: 0;
              border: 3px solid $wcm-border-gray;
              opacity: .7;
              filter: alpha(opacity=70);
            }
          }

          a > img:hover,
          .cycle-pager-active > img,
          .cycle-slide-active > img {
            border: 3px solid $wcm-bright-orange;
            opacity: 1;
            filter: alpha(opacity=100);
          }
        }//#thumbnail-pager

      }//.thumbnail-controller-wrap-inner

    }//.thumbnail-controller-wrap

  }//.thumbnail-controller


  /* =Mobile Pager / Slide Counter
  ----------------------------------------------------------------------------------*/
  // The mobile pager is not displayed in tablet or desktop view
  .mobile-pager{
    @include breakpoint($sm) {
      display: none;
    }

    a{
      float: left;
      width: 35%;
      color: #aeb8bf;
      font-family: "SSStandard";
      margin: 2px 0 0;
      font-size: 38px;

      &:hover{
        color: #aeb8bf;
        text-decoration: none;
      }
    }

    .previous{
      text-align: right;
    }

    .next {
      text-align: left;
    }

    p.slide-counter{
      float: left;
      width: 20%;
      background: $wcm-med-gray;
      color: #fff;
      font-weight: bold;
      margin: 0 15px;
      padding: 15px 0;

      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      font-size: 12px;
      text-align: center;
    }

  }//.mobile-pager

}//.view-carousel
