/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */


/* =AV研究所 Colors and Fonts
----------------------------------------------------------*/

$wcm-white: #fff;
$wcm-red: #b31b1b;
$wcm-dark-orange: #cf4520;
$wcm-bright-orange: #e7751d;
$wcm-yellow: #ffc72c;
$wcm-black: #000000;
$wcm-dark-gray: #555555;
$wcm-med-gray: #666666;
$wcm-border-gray: #dddddd;
$wcm-bg-gray: #f7f7f7;

$wcm-bold: '1898Sans-Bold', sans-serif;
$wcm-regular: '1898Sans-Regular', sans-serif;
$wcm-italic: '1898Sans-Italic', sans-serif;

/* =Scaffolding
----------------------------------------------------------*/

$text-color:            $wcm-black;
$link-color:            $wcm-dark-orange;
$link-hover-color:      $wcm-bright-orange;
$link-hover-decoration: none;

/* =Typography
----------------------------------------------------------*/

$font-size-base:          15px;

$font-size-h1:            40px;
$font-size-h2:            30px;
$font-size-h3:            20px;
$font-size-h4:            18px;
$font-size-h5:            16px;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.666666667; // 25/15

$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: '1898Sans-Bold', sans-serif;
$headings-font-weight: normal;
$headings-color: $wcm-red;

// Basics of a navbar
$navbar-border-radius: 0px;
$navbar-padding-horizontal: 0;

$navbar-default-color: #fff;
$navbar-default-bg: transparent;

// Navbar links
$navbar-default-link-color:                $wcm-dark-orange;
$navbar-default-link-hover-color:          $wcm-dark-orange;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         $wcm-dark-orange;
$navbar-default-link-active-bg:            transparent;
$navbar-default-link-disabled-color:       #ccc;

/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/

$grid-gutter-width:         40px;

/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/

$btn-font-weight:                normal;

$btn-default-color:              $wcm-white;
$btn-default-bg:                 $wcm-dark-orange;
$btn-default-border:             $wcm-dark-orange;

$btn-primary-color:              $wcm-white;
$btn-primary-bg:                 $wcm-dark-orange;
$btn-primary-border:             $wcm-dark-orange;

/* =Forms
----------------------------------------------------------*/

$input-border:                   $wcm-border-gray;

// Border color for inputs on focus
$input-border-focus:             $wcm-dark-orange;

/* =Pagination
----------------------------------------------------------*/

$pagination-active-bg:                 $wcm-dark-orange;
$pagination-active-border:             $wcm-dark-orange;